<template>
  <div class="join-container">
    <p class="title--small text-centered">
      <router-link class="nav-link shadow" id="Join" to="/join">I've heard enough - let me <span
          class="highlighted-text">join!</span></router-link>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: "JoinButton",
  props: {
    buttonText: {
      type: String,
      required: false
    },
  }

});
</script>

<style scoped>
@import url(../../style/vars.css);
@import url(../../style/view/general.css);


.join-container {
  text-align: center;
}

#Join {
  padding: 0.5em 1em;
  color: #fff;
  background-color: #333;
  text-decoration: none;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#Join:hover {
  background-color: var(--dbhubs-red);
  color: #fff;
}

#Join:hover>.highlighted-text {
  color: white;
}

@media (max-width: 853px) {
  #Join {
    font-size: 1.5rem;
  }
}

/* Media queries for responsiveness */
@media (max-width: 570px) {
  #Join {
    font-size: 1rem;
  }
}
</style>