import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/attributions',
    name: 'attributions',
    component: () => import('../views/Attributions.vue'),
  },
  {
    path: '/coaches',
    name: 'coaches',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/JoinView.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/MoreInfo.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FAQ.vue'),
  },
  {
    path: '/testimonials',
    name: 'testimonials',
    component: ()=>import('../views/Testimonials.vue'),
  },
  { path: "/:pathMatch(.*)*", 
    name: "NotFound", 
    component:  ()=>import('../views/NotFound.vue') },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHashHistory(),
  routes,
});

export default router;
